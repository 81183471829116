import {
  mdiAccountHardHat,
  mdiAccountOutline,
  mdiBriefcaseVariantOutline,
  mdiClipboardCheckOutline,
  mdiFileDocumentEditOutline,
  mdiTrafficCone
} from "@mdi/js";

import { getConstructionRecordCount } from "@/views/apps/construction-record/useConstructionRecord";
import { getContractCount } from "@/views/apps/contract/useContract";
import { getDailyLogCount } from "@/views/apps/daily-log/useIdr";
import { getPermitCount } from "@/views/apps/permit/usePermit";
import { getProjectCount } from "@/views/apps/project/useProject";
import { getUserCount } from "@/views/apps/user/useUser";

export function useEntityCounts() {
  const { userCount } = getUserCount();
  const { projectCount } = getProjectCount();
  const { contractCount } = getContractCount();
  const { constructionRecordCount } = getConstructionRecordCount();
  const { dailyLogCount } = getDailyLogCount();
  const { permitCount } = getPermitCount();

  const siteStatistics = [
    {
      title: "Users",
      count: userCount,
      icon: mdiAccountOutline,
      color: "success"
    },
    {
      title: "Projects",
      count: projectCount,
      icon: mdiBriefcaseVariantOutline,
      color: "warning"
    },
    {
      title: "Contracts",
      count: contractCount,
      icon: mdiFileDocumentEditOutline,
      color: "info"
    },
    {
      title: "Construction Sites",
      count: constructionRecordCount,
      icon: mdiTrafficCone,
      color: "accent"
    },
    {
      title: "Daily Logs",
      count: dailyLogCount,
      icon: mdiAccountHardHat,
      color: "secondary"
    },
    {
      title: "Permits",
      count: permitCount,
      icon: mdiClipboardCheckOutline,
      color: "info"
    }
  ];

  return {
    siteStatistics
  };
}
