import { ref } from "@vue/composition-api";

import { store } from "@/store";

export function getPermitCount() {
  const permitCount = ref(0);
  let params = { query: {}, paginate: true };
  store.dispatch("permits/count", params).then((response) => {
    permitCount.value = response;
  });

  return {
    permitCount
  };
}
