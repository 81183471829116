<script>
  import { defineComponent } from "@vue/composition-api";

  import { useEntityCounts } from "./useAnalytics";

  export default defineComponent({
    setup() {
      const { siteStatistics } = useEntityCounts();

      return {
        siteStatistics
      };
    }
  });
</script>

<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Site Statistics</span>

      <v-spacer></v-spacer>

      <v-chip class="v-chip-light-bg primary--text font-weight-semibold" small>
        Cumulative View
      </v-chip>
    </v-card-title>

    <v-card-subtitle class="mb-7">
      <span class="font-weight-semibold text--primary me-1">
        What's been happening in TrafficPM
      </span>
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col
          v-for="stat in siteStatistics"
          :key="stat.title"
          cols="6"
          sm="2"
          class="d-flex align-center"
        >
          <v-avatar size="44" :color="stat.color" rounded class="elevation-1">
            <v-icon dark color="white" size="30">
              {{ stat.icon }}
            </v-icon>
          </v-avatar>

          <div class="ms-3">
            <p class="text-xs mb-0">{{ stat.title }}</p>
            <h3 class="text-xl font-weight-bold">
              {{ stat.count.value }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
