<script>
  import { defineComponent } from "@vue/composition-api";

  import { store } from "@/store";

  import AnalyticsSiteStatistics from "./AnalyticsSiteStatistics.vue";

  export default defineComponent({
    components: {
      AnalyticsSiteStatistics
    },
    setup() {
      function sendSms() {
        console.log("Rarr!");
        store.dispatch("fiscal-years/find");
      }

      return {
        sendSms
      };
    }
  });
</script>

<template>
  <div>
    <analytics-site-statistics />
  </div>
</template>
